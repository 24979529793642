import { router, useForm, usePage } from '@inertiajs/react';
import React, { useEffect, useState } from 'react';
import PrimaryButton from '../PrimaryButton';
import FormControl from '../Form/FormControl';
import Label from '../Form/Label';
import TextInput from '../Form/TextInput';
import ImageInput from '../Form/ImageInput';
import axios from 'axios';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import SelectInput from '../Form/SelectInput';
import { findContinent, maxDate } from '@/utils';

function UserOnboarding() {
    const maxDateLimit = maxDate();

	countries.registerLocale(enLocale);

	const countryObj = countries.getNames('en', {
		select: 'official'
	});
	const countryArr = Object.entries(countryObj).map(([key, value]) => {
		return {
			label: value,
			value: key
		};
	});

	const { auth, constants } = usePage().props;
	const games = constants.games;
	const { user, onboarding } = auth;

	const country = user.profile?.country || null;

	const { data, setData, processing, reset, errors, setError, clearErrors } = useForm({});

	const [step, setStep] = useState(0);
	const [communities, setCommunities] = useState(null);
	const [joinedCommunities, setJoinedCommunities] = useState([]);
	const [createdCommunity, setCreatedCommunity] = useState(null);

	const handleNextButton = (e) => {
		switch (step) {
			case 0:
				updateProfile(e);
				return;
			case 1:
				if (data.games === undefined || data.games?.length === 0) {
					setError('games', 'Please select one or more games.');
					return;
				}
				updateGames(e);
				return;
			case 2:
				updateOnboarding();
				return;
			case 3:
				if (!createdCommunity) return;
				updateOnboarding();
				return;
		}
	};

	const updateProfile = (e) => {
		e.preventDefault();
		clearErrors();

		router.post(
			route('profile.update'),
			{ ...data, _method: 'PUT' },
			{
				onSuccess: () => {
					reset();
					updateOnboarding();
				},
				onError: (e) => Object.keys(e).map((key) => setError(key, e[key]))
			}
		);
	};

	const updateGames = (e) => {
		e.preventDefault();
		clearErrors();

		const payload = { ...data, _method: 'PUT' };

		router.post(route('profile.update.games'), payload, {
			onSuccess: () => {
				reset();
				updateOnboarding();
			},
			onError: (e) => Object.keys(e).map((key) => setError(key, e[key]))
		});
	};

	const updateOnboarding = () => {
		router.post(route('onboarding.update'), { index: step });
	};

	const userCommunities = async () => {
		const res = await axios.get(route('onboarding.userCommunities'));

		const { userCommunities } = await res.data;

		const comIds = userCommunities.map((x) => x.id);

		setJoinedCommunities(comIds);
	};

	const fetchCommunities = async () => {
		const res = await axios.get(route('onboarding.communities'), {
			params: { region: findContinent(constants.countries, country) }
		});

		const { communities } = await res.data;

		const x = communities.slice(0, 6);

		setCommunities(x);
	};

	const createCommunity = (e) => {
		e.preventDefault();

		const payload = { ...data };

		router.post(route('communities.store'), payload, {
			onSuccess: (response) => {
				const { data } = response.props.toast;

				setCreatedCommunity(data);

				updateOnboarding();
			},
			onError: (e) => Object.keys(e).map((key) => setError(key, e[key])),
			onFinish: () => reset()
		});
	};

	const joinHandle = (community) => {
		router.post(
			route('communities.join', community),
			{},
			{
				onSuccess: () => {
					userCommunities();
				}
			}
		);
	};

	const visitProfile = (e) => {
		e.preventDefault();

		router.visit(route('profile.show', user), {
			onFinish: () => document.getElementById('onboardingModal').close()
		});
	};

	const handleSkipButton = (e) => {
		setStep((prev) => prev + 1);

		if (step >= 3) {
			router.put(
				route('profile.update.onboarded'),
				{ onboarded: true },
				{
					onSuccess: () => {
						setCreatedCommunity({});
						updateOnboarding();
					}
				}
			);
		}
	};

	useEffect(() => {
		if (!auth.user) return;

		if (!onboarding.inProgress || onboarding.onboarded) return;

		if (onboarding.nextStep.skippable && onboarding.nextStep.skipped) return;

		setStep(onboarding.steps.findIndex((x) => x.title === onboarding.nextStep.title));

		if (onboarding.nextStep.title === 'Join a community') {
			fetchCommunities();
			userCommunities();
		}

		document.getElementById('onboardingModal').showModal();
	}, [auth]);

	useEffect(() => {
		if (step === 5 || auth.user.onboarded) document.getElementById('onboardingModal').close();
	}, [step]);

	const renderView = (step) => {
		switch (step) {
			case 0:
				return (
					<>
						<h3 className="font-bold text-lg text-center">Welcome to OnlyRacing!</h3>
						<div className="py-4 flex flex-col gap-4 items-center justify-center">
							<FormControl className={'hidden md:flex justify-center items-center'}>
								<Label name="Avatar"></Label>
								<small className="label-alt text-mute -mt-2 mb-2">
									Recommended Size: 300 (W) x 300 (H)
								</small>
								<ImageInput
									name="avatar"
									errors={errors.avatar}
									value={user?.avatar}
									display="avatar"
									onChange={(avatar) => setData('avatar', avatar)}
								/>
								{errors.avatar && (
									<div className="text-error text-xs">{errors.avatar}</div>
								)}
							</FormControl>
							<FormControl>
								<Label name="First Name"></Label>
								<TextInput
									name="first_name"
									value={data.first_name}
									errors={errors.first_name}
									onChange={(e) => setData('first_name', e.target.value)}
									className="w-full"
								/>
								{errors.first_name && (
									<div className="text-error text-xs">{errors.first_name}</div>
								)}
							</FormControl>
							<FormControl>
								<Label name="Last Name"></Label>
								<TextInput
									name="last_name"
									value={data.last_name}
									errors={errors.first_name}
									onChange={(e) => setData('last_name', e.target.value)}
									className="w-full"
								/>
								{errors.last_name && (
									<div className="text-error text-xs">{errors.last_name}</div>
								)}
							</FormControl>
                            <FormControl>
                                <Label name="Birthday"></Label>
                                <TextInput
                                    name="birthday"
                                    errors={errors.birthday}
                                    value={data.birthday}
                                    className="w-full dark:[color-scheme:dark]"
                                    type="date"
                                    max={maxDateLimit}
                                    onChange={(e) =>
                                        setData(
                                            'birthday',
                                            e.target.value
                                        )
                                    }
                                />
                                {errors.birthday && (
                                    <div className="text-error text-xs">
                                        {errors.birthday}
                                    </div>
                                )}
                            </FormControl>


							<FormControl>
								<Label name="Country"></Label>
								<SelectInput
									name="country"
									value={data.country}
									errors={errors.country}
									className="w-full"
									onChange={(e) => setData('country', e.target.value)}
								>
									<option disabled selected>
										Select Country
									</option>
									{!!countryArr?.length &&
										countryArr.map(({ label, value }) => (
											<option value={value} key={value}>
												{label}
											</option>
										))}
								</SelectInput>
								{errors.country && (
									<div className="text-error text-xs">{errors.country}</div>
								)}
							</FormControl>
						</div>
					</>
				);
			case 1:
				return (
					<>
						<h3 className="font-bold text-lg text-center">What games do you play?</h3>
						<div className="overflow-y-scroll max-h-[98%] px-1">
							{games?.map((game, i) => (
								<FormControl>
									<label className="label cursor-pointer">
										<span className="label-text">{game.name}</span>
										<input
											type="checkbox"
											className="checkbox"
											onChange={() => {
												if (data.games === undefined) {
													setData('games', [game.id]);
												} else {
													if (data.games.indexOf(game.id) === -1) {
														setData((data) => ({
															games: [...data.games, game.id]
														}));
													} else {
														setData((data) => ({
															games: [
																...data.games.filter(
																	(x) => x !== game.id
																)
															]
														}));
													}
												}
											}}
										/>
									</label>
								</FormControl>
							))}
						</div>

						{errors.games && <div className="text-error text-xs">{errors.games}</div>}
					</>
				);
			case 2:
				return (
					<>
						<h3 className="font-bold text-lg text-center">Join a community</h3>
						<p className="mb-10">
							We found a few communities that you might be interested in joining based
							on your region and games you play.
						</p>
						{communities?.map((community, i) => (
							<div className="flex justify-between my-4">
								<span>{community.name}</span>
								<PrimaryButton onClick={() => joinHandle(community)}>
									{joinedCommunities.indexOf(community.id) === -1
										? 'Join'
										: 'Leave'}
								</PrimaryButton>
							</div>
						))}
						{errors.communities && (
							<div className="text-error text-xs">{errors.communities}</div>
						)}
					</>
				);
			case 3:
			case 4:
				return (
					<>
						{!createdCommunity ? (
							<>
								<h3 className="font-bold text-lg text-center">
									Create A Community
								</h3>
								<p className="mb-10">
									Looking to create your own community on Only Racing and setup
									your own events and championships? Enter your desired community
									name below.
								</p>
								<form id="createCommunity" onSubmit={createCommunity}>
									<div className="flex flex-col sm:flex-row items-end gap-4">
										<FormControl>
											<Label name="Community name"></Label>
											<TextInput
												name="name"
												value={data.name}
												errors={errors.name}
												onChange={(e) => setData('name', e.target.value)}
											/>
											{errors.name && (
												<div className="text-error text-xs">
													{errors.name}
												</div>
											)}
										</FormControl>

										<PrimaryButton
											type="submit"
											form="createCommunity"
											className="mt-4 w-full sm:w-fit"
											processing={processing}
										>
											Create
										</PrimaryButton>
									</div>
								</form>
							</>
						) : (
							<div>
								<>
									<div className="w-full carousel rounded-box">
										{Object.keys(createdCommunity).length > 0 && (
											<>
												<div
													id="slide1"
													className="carousel-item w-full relative flex flex-col"
												>
													<h3 className="font-bold text-lg text-center">
														Create An Event
													</h3>
													<img
														src="../images/EventOnboarding.gif"
														alt=""
													/>
													<br />

													<small>
														To create an event, head over to your
														community and select <strong>Events</strong>{' '}
														tab, scroll down and click on{' '}
														<strong>Create Event</strong>.
													</small>
													<br />

													<a
														href="#slide2"
														className="btn btn-primary m-2 w-fit ml-auto"
													>
														Next
													</a>
												</div>
												<div
													id="slide2"
													className="carousel-item w-full relative flex flex-col"
												>
													<h3 className="font-bold text-lg text-center">
														Create A Championship
													</h3>

													<img
														src="../images/ChampionshipOnboarding.gif"
														alt=""
													/>
													<br />

													<small>
														To create an event, head over to your
														community and select{' '}
														<strong>Championships</strong> tab, scroll
														down and click on{' '}
														<strong>Create Championship</strong>.
													</small>
													<br />
													<div className="flex justify-between">
														<a
															href="#slide1"
															className="btn btn-primary m-2"
														>
															Previous
														</a>
														<a
															href="#slide3"
															className="btn btn-primary m-2"
														>
															Next
														</a>
													</div>
												</div>
											</>
										)}
										<div
											id="slide3"
											className="carousel-item w-full relative flex flex-col"
										>
											<h3 className="font-bold text-lg text-center">
												You have completed your onboarding!
											</h3>
											<br />
											<small>
												Thank you for completing our user onboarding
												process! We're thrilled to have you on board and
												ready to embark on this journey with us, including
												other sim racers. Your commitment to getting started
												is the first step toward a seamless and enjoyable
												experience with Only Racing.
											</small>
											<br />
											<PrimaryButton
												onClick={visitProfile}
												className="m-2 mt-auto"
											>
												Go to Profile
											</PrimaryButton>
										</div>
									</div>
								</>
							</div>
						)}
					</>
				);
		}
	};

	return (
		<dialog id="onboardingModal" className="modal">
			<div className="modal-box h-[680px] flex flex-col justify-between md:h-[750px]">
				<form method="dialog">
					<button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
						✕
					</button>
				</form>
				<div className="h-full flex flex-col justify-between">
					<div>
						{renderView(
							onboarding.nextStep
								? onboarding.steps.findIndex(
										(x) => x.title === onboarding.nextStep.title
								  )
								: step
						)}
					</div>

					<div className="flex flex-col">
						<span className="text-right w-full font-bold">
							{onboarding.percentage}%
						</span>
						<progress
							className="progress progress-success w-full"
							value={onboarding.percentage}
							max="100"
						></progress>

						{onboarding.inProgress && (
							<div className="flex justify-between">
								{onboarding.nextStep.skippable && (
									<PrimaryButton onClick={handleSkipButton} className="mt-4">
										Skip
									</PrimaryButton>
								)}

								{onboarding.nextStep?.title !=
									onboarding.steps[onboarding.steps.length - 1].title && (
									<PrimaryButton
										onClick={handleNextButton}
										className="ml-auto mt-4"
									>
										Next
									</PrimaryButton>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
			<form method="dialog" className="modal-backdrop">
				<button>close</button>
			</form>
		</dialog>
	);
}

export default UserOnboarding;
